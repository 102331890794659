const dataStore = {
    reel: {
        title: "Showreel 2024",
        hostedVideoUrl: "https://youtu.be/0pq43_NNfq8",
    },
    compositions: [
        {
            id: "1",
            type: "png",
            title: "meditating cruva",
            imageUrl: "/images/work/compositions/meditating cruva.png",
        },
        {
            id: "2",
            type: "video",
            title: "tasty honey",
            imageUrl: "/images/work/compositions/tasty honey.mp4",
        },
        {
            id: "3",
            type: "video",
            title: "pride month",
            imageUrl: "/images/work/compositions/pride month.mp4",
        },
        {
            id: "4",
            type: "png",
            title: "gamer hawk",
            imageUrl: "/images/work/compositions/gamer hawk.png",
        },
        {
            id: "5",
            type: "png",
            title: "electrified nikola",
            imageUrl: "/images/work/compositions/electrified nikola.png",
        },
        {
            id: "6",
            type: "video",
            title: "environment day",
            imageUrl: "/images/work/compositions/environment day.mp4",
        },
        {
            id: "7",
            type: "png",
            title: "masked musa",
            imageUrl: "/images/work/compositions/masked musa.png",
        },
        {
            id: "8",
            type: "png",
            title: "sobbing olivia",
            imageUrl: "/images/work/compositions/sobbing olivia.png",
        },
        {
            id: "9",
            type: "png",
            title: "charming chloe",
            imageUrl: "/images/work/compositions/charming chloe.png",
        },
        {
            id: "10",
            type: "video",
            title: "father's love",
            imageUrl: "/images/work/compositions/father's love.mp4",
        },
        {
            id: "11",
            type: "video",
            title: "masterchef",
            imageUrl: "/images/work/compositions/masterchef.mp4",
        },
        {
            id: "12",
            type: "png",
            title: "tribe pride",
            imageUrl: "/images/work/compositions/tribe pride.png",
        },
        {
            id: "13",
            type: "png",
            title: "evil boombasa",
            imageUrl: "/images/work/compositions/evil boombasa.png",
        },
        {
            id: "14",
            type: "png",
            title: "mukhi x kitty",
            imageUrl: "/images/work/compositions/mukhi x kitty.png",
        },
        {
            id: "15",
            type: "png",
            title: "destiny",
            imageUrl: "/images/work/compositions/destiny.png",
        },
    ],
    series: [
        {
            id: "1",
            title: "oremo-adventures",
            type: "series",
            imageUrl: "/images/work/series/oremo adventures/title poster.mp4",
            bannerUrl: "/images/work/series/oremo adventures/title banner.png",
            description:
                "Little blobs of mushiness bound to a profound friendship, well known in the town for making a lot of mischiefs. Oreo and Momo have been dispatched to this planet to spread smiles across faces.",
            process: [
                "/images/work/series/oremo adventures/episodes/process/series intro_storyboard.png",
                "/images/work/series/oremo adventures/episodes/process/a little sweet, a little salty_storyboard.png",
                "/images/work/series/oremo adventures/episodes/process/space mission_storyboard.png",
            ],
            seriesEpisodes: [
                {
                    id: "1",
                    title: "A Hello",
                    bannerImageUrl:
                        "/images/work/series/oremo adventures/episodes/series intro/series intro.png",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/series%20intro/thumbnail.png?token=AFL433VMEK7QH6WYJWT7HH3BR7RL2",
                    hostedVideoUrl: "https://youtu.be/DQ22iEA1clo",
                },
                {
                    id: "2",
                    title: "a little sweet, a little salty",
                    bannerImageUrl:
                        "/images/work/series/oremo adventures/episodes/a little sweet, a little salty/a little sweet, a little salty.png",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/a%20little%20sweet%2C%20a%20little%20salty/thumbnail.png?token=AFL433SUB7MNSB4YJTDJA3LBRFF7U",
                    hostedVideoUrl: "https://youtu.be/4o5rzMj9nac",
                },
                {
                    id: "3",
                    title: "space mission",
                    bannerImageUrl:
                        "/images/work/series/oremo adventures/episodes/space mission/space mission.png",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/space%20mission/thumbnail.png?token=AFL433VKJS3CHYHSLU33QBLBRFF4O",
                    hostedVideoUrl: "https://youtu.be/EqQ2biRbOVo",
                },
            ],
        },
        {
            id: "2",
            title: "fluffycat",
            type: "series",
            imageUrl: "/images/work/series/fluffycat/title poster.mp4",
            bannerUrl: "/images/work/series/fluffycat/title banner.png",
            description:
                "Cute puckish munchkin, either found snacking on a piece of meat or chasing down some filthy rats. She may seem innocent, but she’s got a lot of lunacy up her sleeves.",
            process: [
                "/images/work/series/fluffycat/episodes/process/always hungry_storyboard.png",
                "/images/work/series/fluffycat/episodes/process/humorous battle_storyboard.png",
                "/images/work/series/fluffycat/episodes/process/roll, eat, fart, repeat_storyboard.png",
            ],
            seriesEpisodes: [
                {
                    id: "1",
                    title: "always hungry",
                    bannerImageUrl:
                        "/images/work/series/fluffycat/episodes/always hungry/always hungry.png",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/fluffycat/episodes/always%20hungry/thumbnail.png?token=AFL433VLSDAMR4BFQUNTRKTBRFGB4",
                    hostedVideoUrl: "https://www.youtube.com/embed/fPGRAk8RcOs",
                },
                {
                    id: "2",
                    title: "humorous battle",
                    bannerImageUrl:
                        "/images/work/series/fluffycat/episodes/humorous battle/humorous battle.png",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/fluffycat/episodes/humorous%20battle/thumbnail.png?token=AFL433X5GQAKNE4UNSLXO2TBRFGDW",
                    hostedVideoUrl: "https://www.youtube.com/embed/FONVnswrXGE",
                },
                {
                    id: "3",
                    title: "roll, eat, fart, repeat",
                    bannerImageUrl:
                        "/images/work/series/fluffycat/episodes/roll, eat, fart, repeat/roll, eat, fart, repeat.png",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/fluffycat/episodes/roll%2C%20eat%2C%20fart%2C%20repeat/thumbnail.png?token=AFL433TWFDBHR4RW73ZAOC3BRFGGE",
                    hostedVideoUrl: "https://www.youtube.com/embed/Io1TrXciBKs",
                },
            ],
            seriesExtras: {
                extras: "/images/work/series/oremo adventures/extras/extras.png",
            },
        },
        {
            id: "3",
            title: "frisky-lucas",
            type: "collection",
            imageUrl: "/images/work/series/frisky lucas/title poster.mp4",
            bannerUrl: "/images/work/series/frisky lucas/title banner.png",
            description:
                "Playful toddler who is unaware of the fact that life can be lived with ease, always creating a nuisance with his various little ventures, keeping his life eventful and action-packed.",
            seriesEpisodes: [
                {
                    id: "1",
                    title: "cosmonaut",
                    type: "video",
                    bannerImageUrl:
                        "/images/work/series/frisky lucas/episodes/cosmonaut.mp4",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/series%20intro/thumbnail.png?token=AFL433VMEK7QH6WYJWT7HH3BR7RL2",
                    hostedVideoUrl: "https://www.youtube.com/embed/ReSqXRNyrOc",
                },
                {
                    id: "2",
                    title: "shattered",
                    type: "video",
                    bannerImageUrl:
                        "/images/work/series/frisky lucas/episodes/shattered.mp4",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/series%20intro/thumbnail.png?token=AFL433VMEK7QH6WYJWT7HH3BR7RL2",
                    hostedVideoUrl: "https://www.youtube.com/embed/ReSqXRNyrOc",
                },
                {
                    id: "3",
                    title: "predator",

                    type: "video",
                    bannerImageUrl:
                        "/images/work/series/frisky lucas/episodes/predator.mp4",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/series%20intro/thumbnail.png?token=AFL433VMEK7QH6WYJWT7HH3BR7RL2",
                    hostedVideoUrl: "https://www.youtube.com/embed/ReSqXRNyrOc",
                },
                {
                    id: "4",
                    title: "camouflage",

                    type: "video",
                    bannerImageUrl:
                        "/images/work/series/frisky lucas/episodes/camouflage.mp4",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/series%20intro/thumbnail.png?token=AFL433VMEK7QH6WYJWT7HH3BR7RL2",
                    hostedVideoUrl: "https://www.youtube.com/embed/ReSqXRNyrOc",
                },
                {
                    id: "5",
                    title: "surge",

                    type: "video",
                    bannerImageUrl:
                        "/images/work/series/frisky lucas/episodes/surge.mp4",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/series%20intro/thumbnail.png?token=AFL433VMEK7QH6WYJWT7HH3BR7RL2",
                    hostedVideoUrl: "https://www.youtube.com/embed/ReSqXRNyrOc",
                },
                {
                    id: "6",
                    title: "slugabed",

                    type: "video",
                    bannerImageUrl:
                        "/images/work/series/frisky lucas/episodes/slugabed.mp4",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/series%20intro/thumbnail.png?token=AFL433VMEK7QH6WYJWT7HH3BR7RL2",
                    hostedVideoUrl: "https://www.youtube.com/embed/ReSqXRNyrOc",
                },
            ],
        },
        {
            id: "4",
            title: "close-to-heart",
            type: "collection",
            imageUrl: "/images/work/series/close to heart/title poster.mp4",
            bannerUrl: "/images/work/series/close to heart/title banner.png",
            description:
                "Anime has always been at the core of my heart, and in this journey, certain characters become more important than the others, just like Ena Komiya (Just Because) & Tsubaki Sawabe (Your Lie In April), they stuck to my heart and will stay here forever.",
            seriesEpisodes: [
                {
                    id: "1",
                    title: "close to heart",
                    type: "video",
                    bannerImageUrl:
                        "/images/work/series/close to heart/episodes/close to heart.mp4",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/series%20intro/thumbnail.png?token=AFL433VMEK7QH6WYJWT7HH3BR7RL2",
                    hostedVideoUrl: "https://www.youtube.com/embed/ReSqXRNyrOc",
                },
                {
                    id: "2",
                    title: "title sequence",

                    type: "video",
                    bannerImageUrl:
                        "/images/work/series/close to heart/episodes/title sequence.mp4",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/series%20intro/thumbnail.png?token=AFL433VMEK7QH6WYJWT7HH3BR7RL2",
                    hostedVideoUrl: "https://www.youtube.com/embed/ReSqXRNyrOc",
                },
                {
                    id: "3",
                    title: "komiya",

                    type: "video",
                    bannerImageUrl:
                        "/images/work/series/close to heart/episodes/komiya.mp4",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/series%20intro/thumbnail.png?token=AFL433VMEK7QH6WYJWT7HH3BR7RL2",
                    hostedVideoUrl: "https://www.youtube.com/embed/ReSqXRNyrOc",
                },
                {
                    id: "4",
                    title: "komiya (japanese)",

                    type: "video",
                    bannerImageUrl:
                        "/images/work/series/close to heart/episodes/komiya(japanese).mp4",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/series%20intro/thumbnail.png?token=AFL433VMEK7QH6WYJWT7HH3BR7RL2",
                    hostedVideoUrl: "https://www.youtube.com/embed/ReSqXRNyrOc",
                },
                {
                    id: "5",
                    title: "tsubaki",

                    type: "video",
                    bannerImageUrl:
                        "/images/work/series/close to heart/episodes/tsubaki.mp4",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/series%20intro/thumbnail.png?token=AFL433VMEK7QH6WYJWT7HH3BR7RL2",
                    hostedVideoUrl: "https://www.youtube.com/embed/ReSqXRNyrOc",
                },
                {
                    id: "6",
                    title: "tsubaki (japanese)",

                    type: "video",
                    bannerImageUrl:
                        "/images/work/series/close to heart/episodes/tsubaki(japanese).mp4",
                    thumbnailUrl:
                        "https://raw.githubusercontent.com/avi-spc/creativekonami/master/public/images/work/series/oremo%20adventures/episodes/series%20intro/thumbnail.png?token=AFL433VMEK7QH6WYJWT7HH3BR7RL2",
                    hostedVideoUrl: "https://www.youtube.com/embed/ReSqXRNyrOc",
                },
            ],
        },
        {
            id: "5",
            title: "she's-watching",
            type: "project",
            imageUrl: "/images/work/series/she's watching/title poster.mp4",
            bannerUrl: "/images/work/series/she's watching/title banner.png",
            description:
                "A mother's love is indescribable, from sleepless nights changing our diapers to sleeping hungry stuffing our bellies with her food. Whether a toddler or a grown young adult, she will watch out for us her whole life.",
            spotImageUrl:
                "/images/work/series/she's watching/episodes/she's watching.mp4",
            process: [
                "/images/work/series/she's watching/episodes/process/storyboard.png",
            ],
            seriesEpisodes: {},
        },
        {
            id: "6",
            title: "dusk's-embrace",
            type: "project",
            imageUrl: "/images/work/series/dusk's embrace/title poster.png",
            bannerUrl: "/images/work/series/dusk's embrace/title banner.png",
            description:
                "As the sun dips below the horizon, dusk's embrace wraps the world in a tender twilight, where daydreams and night whispers intertwine. In this fleeting moment, shadows stretch long, and the world pauses, caught between the fading light and the coming night.",
            spotImageUrl:
                "/images/work/series/dusk's embrace/episodes/dusk's embrace.png",
            process: [
                "/images/work/series/dusk's embrace/episodes/process/pipeline.png",
            ],
            seriesEpisodes: {},
        },
    ],
    comics: [
        {
            id: "1",
            title: "rainy-day",
            imageUrl: "/images/work/comics/rainy days/title poster.png",
            bannerUrl: "/images/work/comics/rainy days/title banner.png",
            description:
                "Either studious or naughty-minded, believe it or not, we all hated going to school early in the morning. A rainy day was our only glimmer of hope in those days.",
            comicCollection: [
                {
                    id: "1",
                    stripImageUrl:
                        "/images/work/comics/rainy days/collection/rainy day_1.png",
                },
                {
                    id: "2",
                    stripImageUrl:
                        "/images/work/comics/rainy days/collection/rainy day_2.png",
                },
                {
                    id: "3",
                    stripImageUrl:
                        "/images/work/comics/rainy days/collection/rainy day_3.png",
                },
                {
                    id: "4",
                    stripImageUrl:
                        "/images/work/comics/rainy days/collection/rainy day_4.png",
                },
            ],
        },
        {
            id: "2",
            title: "gamer",
            imageUrl: "/images/work/comics/gamer/title poster.png",
            bannerUrl: "/images/work/comics/gamer/title banner.png",
            description:
                "Every gamer in this world, either pro or rookie, has experienced this gruesome incident at some point in their lives.",
            comicCollection: [
                {
                    id: "1",
                    stripImageUrl:
                        "/images/work/comics/gamer/collection/gamer_1.png",
                },
                {
                    id: "2",
                    stripImageUrl:
                        "/images/work/comics/gamer/collection/gamer_2.png",
                },
                {
                    id: "3",
                    stripImageUrl:
                        "/images/work/comics/gamer/collection/gamer_3.png",
                },
                {
                    id: "4",
                    stripImageUrl:
                        "/images/work/comics/gamer/collection/gamer_4.png",
                },
                {
                    id: "5",
                    stripImageUrl:
                        "/images/work/comics/gamer/collection/gamer_5.png",
                },
                {
                    id: "6",
                    stripImageUrl:
                        "/images/work/comics/gamer/collection/gamer_6.png",
                },
            ],
        },
    ],
    games: [
        {
            id: "1",
            title: "labyrinth of vengeance",
            type: "project",
            imageUrl: "/images/work/games/labyrinth of vengeance/title poster.png",
            bannerUrl: "/images/work/games/labyrinth of vengeance/title banner.png",
            description:
                "Labyrinth of Vengeance is an isometric top-down stealth driven 3D platformer that challenges you to ascend a labyrinthine pillar. Master intricate puzzles, outmaneuver relentless adversaries, and traverse perilous platforms as you seek vengeance.",
            spotImageUrl: "/images/work/games/avsaka/episodes/avsaka.jpeg",
            prototypeUrl:
                "",
            codebaseUrl: "https://github.com/avi-spc/labyrinth_of_vengeance",
            process: [
                "/images/work/games/labyrinth of vengeance/episodes/process/process.png",
            ],
            seriesEpisodes: {},
            trailerUrl: "/images/work/games/avsaka/episodes/avsaka.jpeg",
        },
        {
            id: "2",
            title: "avsaka",
            type: "project",
            imageUrl: "/images/work/games/avsaka/title poster.jpeg",
            bannerUrl: "/images/work/games/avsaka/title banner.png",
            description:
                "The game centers on a hero's quest to restore his ravaged home planet, Avsaka, which has been plundered and devastated by his foes. Determined to reclaim what was lost, he embarks on a daring crusade, invading foreign planets, battling formidable enemies, and gathering precious resources. With each victory, he brings back what is needed to rebuild Avsaka to its former glory.",
            spotImageUrl: "/images/work/games/avsaka/episodes/avsaka.jpeg",
            prototypeUrl:
                "https://apps.microsoft.com/detail/9ph3srphgc22?hl=en-GB&gl=IN",
            codebaseUrl: "https://github.com/avi-spc/Save-Avsaka",
            process: [
                "/images/work/games/avsaka/episodes/process/process.png",
            ],
            seriesEpisodes: {},
            trailerUrl: "/images/work/games/avsaka/episodes/avsaka.jpeg",
        },
		{
            id: "3",
            title: "battlearena",
            type: "project",
            imageUrl: "/images/work/games/battlearena/title poster.png",
            bannerUrl: "/images/work/games/battlearena/title banner.png",
            description:
                "An online multiplayer experience built upon Photon Unity Networking framework where players can host lobbies and friends can join the arena to battle with each-other.",
            spotImageUrl: "/images/work/games/avsaka/episodes/avsaka.jpeg",
            prototypeUrl:
                "https://avi-spc.itch.io/battlearena",
            codebaseUrl: "https://github.com/avi-spc/BattleArena_CN",
            process: [
                "/images/work/games/battlearena/episodes/process/process.png",
            ],
            seriesEpisodes: {},
            trailerUrl: "/images/work/games/avsaka/episodes/avsaka.jpeg",
        },
        {
            id: "4",
            title: "saladchef",
            type: "project",
            imageUrl: "/images/work/games/saladchef/title poster.png",
            bannerUrl: "/images/work/games/saladchef/title banner.png",
            description:
                "A 2D restaurant simulation game consiting of local multiplayer where two players compete with each-other and tries to serve and make customers more happy by providing fast and precise salad orders.",
            spotImageUrl: "/images/work/games/avsaka/episodes/avsaka.jpeg",
            prototypeUrl:
                "https://avi-spc.itch.io/saladchef",
            codebaseUrl: "https://github.com/avi-spc/saladchef",
            process: [
                "/images/work/games/saladchef/episodes/process/process.png",
            ],
            seriesEpisodes: {},
            trailerUrl: "/images/work/games/avsaka/episodes/avsaka.jpeg",
        },
    ],
    graphics: [
        {
            id: "1",
            title: "cosmos",
            type: "interaction",
            imageUrl: "/images/work/graphics/cosmos/title poster.png",
            bannerUrl: "/images/work/graphics/cosmos/title banner.png",
            description:
                "Cosmos is an innovative social media platform designed specifically for gaming professionals, hobbyists, game developers, and eSports enthusiasts. The app aims to create a dynamic community where users can share content, collaborate on projects, stay updated on industry news, and participate in eSports events.",
            caseStudyUrl: "https://www.behance.net/gallery/212842185/Cosmos",
            prototypeUrl:
                "https://www.figma.com/proto/Zy4DZUe5S4I2foIEQ30apJ/cosmos-(app)?node-id=472-8788&t=mGLgAUAKdeE3ARit-1",
            process: [
                "/images/work/graphics/cosmos/process/mockups.png",
                "/images/work/graphics/cosmos/process/components.png",
                "/images/work/graphics/cosmos/process/design system.png",
                "/images/work/graphics/cosmos/process/wireframes.png",
                "/images/work/graphics/cosmos/process/screens.png",
            ],
        },
        {
            id: "2",
            title: "omoshiroi",
            type: "interaction",
            imageUrl: "/images/work/graphics/omoshiroi/title poster.png",
            bannerUrl: "/images/work/graphics/omoshiroi/title banner.png",
            description:
                "Omoshiroi is a revolutionary anime streaming app designed to deliver a seamless and immersive experience for anime fans worldwide. Combining a vast library of anime content with innovative features, Omoshiroi caters to all types of viewers, from casual fans to dedicated otakus.",
            caseStudyUrl: "https://www.behance.net/gallery/212870923/Omoshiroi",
            prototypeUrl:
                "https://www.figma.com/proto/hVqh8ndRsrYyUrF4QEem2f/omoshiroi-(app)?node-id=298-920&t=Wegw3sfD6vOYdNvc-1",
            process: [
                "/images/work/graphics/omoshiroi/process/mockups.png",
                "/images/work/graphics/omoshiroi/process/components.png",
                "/images/work/graphics/omoshiroi/process/design system.png",
                "/images/work/graphics/omoshiroi/process/wireframes.png",
                "/images/work/graphics/omoshiroi/process/screens.png",
            ],
        },
        {
            id: "3",
            title: "kamuyaki",
            type: "branding",
            imageUrl: "/images/work/graphics/kamuyaki/title poster.png",
            bannerUrl: "/images/work/graphics/kamuyaki	/title banner.png",
            description:
                "Kamuyaki is dedicated to delivering an authentic and memorable Taiyaki experience. Each piece is meticulously crafted using time-honored techniques and the finest ingredients, ensuring that every bite transports you to the bustling streets of Japan, where the aroma of freshly made Taiyaki fills the air.",
            caseStudyUrl: "https://www.behance.net/gallery/213658753/Kamuyaki",
            // prototypeUrl: "https://www.figma.com/proto/hVqh8ndRsrYyUrF4QEem2f/omoshiroi-(app)?node-id=298-920&t=Wegw3sfD6vOYdNvc-1",
            process: [
                "/images/work/graphics/kamuyaki/process/01.png",
                "/images/work/graphics/kamuyaki/process/02.png",
                "/images/work/graphics/kamuyaki/process/03.png",
                "/images/work/graphics/kamuyaki/process/04.png",
                "/images/work/graphics/kamuyaki/process/05.png",
                "/images/work/graphics/kamuyaki/process/06.png",
                "/images/work/graphics/kamuyaki/process/07.png",
                "/images/work/graphics/kamuyaki/process/08.png",
                "/images/work/graphics/kamuyaki/process/09.png",
            ],
        },
    ],
};

export default dataStore;
